import React, { useContext, useEffect, useState } from "react";
import Add from "./Add";
import Edit from "./Edit";
import Delete_Points_Modal from "../../Common_Component/Common_Modal/Delete_Points_Modal/Delete_Points_Modal";
import { Button, Form, Table } from "react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faMinusCircle,
  faPenToSquare,
  faPlay,
  faPlus,
  faRefresh,
  faRotateRight,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import AppContext from "../../../utils/context";
import {
  DataList,
  DeleteData,
  ChangeStatus,
} from "../../../utils/apis/master/state/state";
import { getAllCountries } from "../../../utils/apis/master/Master";
import { Per_Page_Dropdown, Select2Data } from "../../../utils/common";
import CustomPaginate from "../../Common_Component/CustomPaginate/CustomPaginate";

const Tables = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalEditShow, setModalEditShow] = useState(0);
  const [modalDeleteShow, setModalDeleteShow] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);

  const [countries, setCountries] = useState([]);
  const [country_id, setCountry_id] = useState("");

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "black" : "",
      color: state.isFocused || state.isSelected ? "white" : "white",
      cursor: "pointer",
    }),
  };

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(10);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [changeStatus, setChangeStatus] = useState();

  const getDataAll = async () => {
    const response = await DataList(
      currentPage,
      perPage,
      search,
      country_id?.value
    );
    await setData(response);
    setCurrentPage(response?.data?.current_page);
    setperPage(response?.data?.per_page);
  };

  useEffect(() => {
    getDataAll();
  }, [
    changeStatus,
    perPage,
    reset,
    modalShow,
    modalEditShow,
    search,
    country_id,
    currentPage,
  ]);

  const StatusChange = async (id) => {
    const response = await ChangeStatus(id);
    setChangeStatus(response);
  };

  const handleDeleteRecord = async () => {
    setModalDeleteShow(false);
    if (recordToDeleteId) {
      const response = await DeleteData(recordToDeleteId);
      setRecordToDeleteId(null);
      setChangeStatus(response);
    }
  };

  const GetAllCountry = async () => {
    const response = await getAllCountries();
    if (response?.success) {
      setCountries(await Select2Data(response?.data, "country_id"));
    }
  };

  useEffect(() => {
    GetAllCountry();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <section className="View_List">
        {/* <Heading_Holder headingText={"Banner List"} /> */}

        <div className="search-bar-holder mt-4  mb-3">
          <Form>
            <div className="row">
              <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-5  col-holder">
                <Button
                  type="button"
                  className="search-btn"
                  onClick={() => setModalShow(true)}
                >
                  Add
                </Button>
              </div>

              <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-5   ">
                <Select
                  options={countries}
                  styles={customStyles}
                  value={country_id}
                  placeholder="-- Country --"
                  onChange={(selectedOption) => {
                    setCountry_id(selectedOption);
                  }}
                />
              </div>

              <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-5    ">
                <Form.Group className="form-field" controlId="formBasicEmail">
                  <Form.Control
                    type="text"
                    placeholder="State Name"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                  />
                </Form.Group>
              </div>

              <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-5   col-holder">
                <Button
                  type="button"
                  className="reset-btn"
                  onClick={() => {
                    setCountry_id("");
                    setSearch("");
                    setReset(!reset);
                  }}
                >
                  Reset
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12">
            <div className="user-list-table">
              {/* <Banner_List_Table /> */}

              <div className="data-table-holder">
                <Table responsive striped>
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>Name</th>
                      <th>Country</th>
                      <th>Status</th>
                      <th className="border-none-holder">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {data?.data?.data?.map((d, index) => {
                      return (
                        <tr>
                          <td>
                            {(currentPage - 1) * perPage + index + 1}
                            {/* {index + 1} */}
                          </td>
                          <td>{d?.name}</td>
                          <td>{d?.country?.name}</td>
                          <td>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                checked={d.status}
                                // disabled={!isAllow?.includes(43)}
                                onChange={() => {
                                  StatusChange(d.id);
                                }}
                                id={`flexSwitchCheckDefault${d?.id}`}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`flexSwitchCheckDefault${d?.id}`}
                              >
                                {d.status ? "Active" : "Inactive"}
                              </label>
                            </div>
                          </td>
                          <td className="border-none-holder">
                            <div className="action-holder">
                              <div
                                className="icon-holder"
                                onClick={() => setModalEditShow(d?.id)}
                              >
                                <FontAwesomeIcon icon={faPenToSquare} />
                              </div>

                              <div
                                className="icon-holder"
                                onClick={() => {
                                  setRecordToDeleteId(d?.id);
                                  setModalDeleteShow(true);
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <CustomPaginate
                  currentPage={data?.data?.current_page}
                  totalPages={data?.data?.total_pages}
                  handlePageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Add show={modalShow} onHide={() => setModalShow(false)} />

      <Edit show={modalEditShow} onHide={() => setModalEditShow(false)} />

      <Delete_Points_Modal
        show={modalDeleteShow}
        handleDeleteRecord={handleDeleteRecord}
        onHide={() => setModalDeleteShow(false)}
      />
    </>
  );
};

export default Tables;
