import { GET, POST, PUT, DELETE } from "../../api";

export const UsersGET = async () => {
  try {
    return await GET(`/users`);
  } catch (error) {
    console.log(error);
  }
};

export const SingleData = async (id) => {
  try {
    return await GET(`/users/${id}`);
  } catch (error) {
    console.log(error);
  }
};


export const UpdateWallet = async (id, data) => {
  try {
    return await POST(`/users/update-wallet/${id}`, data);
  } catch (error) {
    console.log(error);
  }
};
