import React from "react";
import { Form, Table } from "react-bootstrap";
import Profile_Frame from "../../Profile/Profile_Frame/Profile_Frame";

function Profile_List_table({ histories }) {
  console.log("histories", histories);
  function formatDate(date) {
    if (!date) return ""; // handle invalid date
    const d = new Date(date);

    // Format date (dd-MM-yyyy)
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();

    // Format time (HH:mm)
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  }

  return (
    <>
      <div className="Profile_List_table profile_tab_table">
        <Profile_Frame />

        <div className="data-table-holder">
          <Table responsive className="table-striped ">
            <thead>
              <tr>
                <th style={{ width: "50px" }}>Sr. No</th>
                <th style={{ width: "100px" }}>Date & Time </th>
                <th style={{ width: "100px" }}>Transaction Type </th>
                <th style={{ width: "100px" }}>Amount </th>
                <th style={{ width: "100px" }}>Comment</th>
                <th className="border-none-holder">Balance</th>
              </tr>
            </thead>

            <tbody>
              {histories?.map((history, index) =>
                <tr key={index}>
                  <td style={{ width: "50px" }}>{index + 1}</td>
                  <td style={{ width: "100px" }}>{formatDate(history?.createdAt)}</td>
                  <td style={{ width: "100px" }}>{history?.type}</td>
                  <td style={{ width: "100px" }}>{history?.amount}</td>
                  <td style={{ width: "100px" }}>{history?.remark}</td>
                  <td className="border-none-holder">{history?.remaining_amount}</td>
                </tr>
              )}

            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default Profile_List_table;
