import React from "react";
import "./Remove_Points_Modal.css"
import { Button, Form, Modal } from "react-bootstrap";
import Modal_Heading from "../../Modal_Heading/Modal_Heading";
import Remove_Points_Modal_Frame from "./Remove_Points_Modal_Frame/Remove_Points_Modal_Frame";
import { useForm, Controller } from "react-hook-form";
import { UpdateWallet } from "../../../../utils/apis/Users";
import classNames from "classnames";

function Remove_Points_Modal(props) {

  const id = props.show;

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const DataToSend = new FormData();
      DataToSend.append("amount", data?.amount);
      DataToSend.append("type", 'debit');
      const response = await UpdateWallet(id, DataToSend);
      if (response?.success) {
        // await setShowModal({ code: response.code, message: response.message });
        reset();
        setTimeout(() => {
          // setShowModal(0);
          props.onHide();
        }, 1000);
      } else {
        // await setShowModal({ code: response.code, message: response.message });
      }

    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <section>
        <Modal
          {...props}
          className=" Add_Points_Modal modal_holder"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal_Heading modalHeading={"Remove Points"} />
          <div className="modal-body-holder">
            <Remove_Points_Modal_Frame />
            <Modal.Body>
              <div className="main-form-holder">
                <Form onSubmit={handleSubmit(onSubmit)} className="form-holder">
                  <div className="row">
                    <div className="col-md-12">
                      <Form.Group
                        className="form-field"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Payment</Form.Label>
                        <Form.Control
                          type="text"
                          name="amount"
                          placeholder="Amount"
                          className={classNames("", {
                            "is-invalid": errors?.amount,
                          })}
                          {...register("amount", {
                            required: "Amount is required",
                          })}
                          onKeyDown={(e) => {
                            if (
                              ["Backspace", "Tab", "ArrowLeft", "ArrowRight", "Delete"].includes(e.key)
                            ) {
                              return;
                            }
                            if (
                              (e.key >= "0" && e.key <= "9") ||
                              (e.key === "." && !e.target.value.includes("."))
                            ) {
                              return;
                            }
                            e.preventDefault();
                          }}
                        />

                        {errors.amount && (
                          <span className="text-danger">{errors.amount.message}</span>
                        )}
                      </Form.Group>
                    </div>


                  </div>
                </Form>

                <div className="modal-footer-holder">
                  <div className="row">
                    <div className=" col-md-6 col-sm-6 col-6">
                      <Button className="search-btn" onClick={props.onHide}>
                        Cancel
                      </Button>
                    </div>

                    <div className=" col-md-6 col-sm-6 col-6">
                      <Button
                        className="reset-btn "
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      </section>
    </>
  );
}

export default Remove_Points_Modal;
