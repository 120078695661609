import React, { useContext, useEffect, useState } from "react";
import Profile_List_table from "./Profile_List_table/Profile_List_table";
import Profile_data_info from "./Profile_data_info/Profile_data_info";
import { SingleData } from "../../utils/apis/Users";
import { useParams } from "react-router-dom";

function Profile_data_List() {
  const { id } = useParams();
  const [data, setData] = useState({})

  const getData = async () => {
    const response = await SingleData(id);
    setData(response?.data);
  }

  useEffect(() => {
    getData();
  }, [id])
  return (
    <>
      <section className="Profile">
        <div className="info-card-holder">
          <Profile_data_info data={data} />
          <Profile_List_table histories={data?.wallet?.wallet_histories} />
        </div>
      </section>
    </>
  );
}

export default Profile_data_List;
