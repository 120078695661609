import React, { useContext, useEffect, useState } from "react";
import Profile_info from './Profile_info/Profile_info'
import Profile_Tab from './Profile_Tab/Profile_Tab'
import { SingleData } from "../../utils/apis/Users";
import { useParams } from "react-router-dom";

function Profile() {
  const { id } = useParams();
  const [data, setData] = useState({})

  const getData = async () => {
    const response = await SingleData(id);
    setData(response?.data);
  }

  useEffect(() => {
    getData();
  }, [id])
  return (
    <>
      <section className='Profile'>
        <div className='info-card-holder'>
          <Profile_info data={data} />
          <Profile_Tab />
        </div>
      </section>
    </>
  )
}

export default Profile