import React from "react";
import "./Buckets.css";
import Border_div from "../Border_div/Border_div";

function Buckets({ BucketText, divText }) {
  return (
    <>
      
        <section className="Buckets">
          <div className="bucket-content">
            <div>
              <div className="buckets-text">
                <h3 className="font-use">{BucketText}</h3>
              </div>
              <div className="count-holder">
                <Border_div divText={divText} />
              </div>
            </div>
          </div>
        </section>        
     

    </>
  );
}

export default Buckets;
