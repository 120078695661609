import React, { useContext, useEffect, useState } from "react";
import "./Header.css";
import { Container, Nav, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../utils/context";
import Cookies from "js-cookie";

function Header() {
  const [activeLink, setActiveLink] = useState("/dashboard"); // Set initial active link
  const [isNavbarOpen, setIsNavbarOpen] = useState(false); // State to control collapse behavior

  const handleNavClick = (path) => {
    setActiveLink(path); // Update active link when a link is clicked
    setIsNavbarOpen(false); // Close the navbar after a link is clicked
  };

  const {
    setUserData,
    setUsertype,
    setSignin,
    userdata,
    usertype,
    isSidebarOpen,
    setSidebarOpen,
    toggleSidebar,
    getData,
    IMG_URL,
  } = useContext(Context);

  const navigate = useNavigate();

  const LogOut = async () => {
    Cookies.remove("warri_sport_admin_security", { path: "/" });
    await setUserData("");
    await setUsertype("");
    await setSignin(false);
    navigate("/");
  };

  const location = useLocation();

  useEffect(() => {
    setActiveLink(location.pathname);
  });

  return (
    <section className="Header_holder">
      <Navbar expand="lg" className="Header">
        <Container fluid>
          <Navbar.Toggle
            aria-controls="navbarScroll"
            onClick={() => setIsNavbarOpen(!isNavbarOpen)} // Toggle navbar collapse state
          />
          <Navbar.Collapse id="navbarScroll" in={isNavbarOpen}>
            {" "}
            {/* Control collapse based on state */}
            <Nav className="me-auto my-2 my-lg-0" navbarScroll>
              <Nav.Link
                as={Link}
                to="/dashboard"
                className={activeLink === "/dashboard" ? "active" : ""}
                onClick={() => handleNavClick("/dashboard")}
              >
                <span className="icon-holder">
                  <img
                    className="icon-img"
                    src={process.env.PUBLIC_URL + "/Icon/dashboard.png"}
                    alt="icon-img"
                  />
                </span>
                Dashboard
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/view-list"
                className={activeLink === "/view-list" ? "active" : ""}
                onClick={() => handleNavClick("/view-list")}
              >
                <span className="icon-holder">
                  <img
                    className="icon-img"
                    src={process.env.PUBLIC_URL + "/Icon/managemnet.png"}
                    alt="icon-img"
                  />
                </span>
                Customer Management
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/view-played-game"
                className={activeLink === "/view-played-game" ? "active" : ""}
                onClick={() => handleNavClick("/view-played-game")}
              >
                <span className="icon-holder">
                  <img
                    className="icon-img"
                    src={process.env.PUBLIC_URL + "/Icon/game.png"}
                    alt="icon-img"
                  />
                </span>
                View Played Game
              </Nav.Link>
            </Nav>
            <div className="header-side-icon-holder">
              <Link to={"/profile-data"}>
                <div
                  className="main-side-icon"
                  onClick={() => handleNavClick("/profile-data")}
                >
                  <div className="side-icon">
                    <FontAwesomeIcon className="icon" icon={faGear} />
                  </div>
                </div>
              </Link>
              <Link to={"/profile"}>
                <div
                  className="main-side-icon"
                  onClick={() => handleNavClick("/profile")}
                >
                  <div className="side-icon">
                    <FontAwesomeIcon className="icon" icon={faUser} />
                  </div>
                </div>
              </Link>
              <Link to={"/"}>
                <div className="main-side-icon" onClick={LogOut}>
                  <div className="side-icon">
                    <FontAwesomeIcon className="icon" icon={faPowerOff} />
                  </div>
                </div>
              </Link>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </section>
  );
}

export default Header;
