import React from "react";
import "./Profile_data_info.css";

function Profile_data_info({ data }) {
  return (
    <>
      <section className="Profile_info Profile_data_info">
        <div className="row">
          <div className="col-lg-12 col-12 ">
            <div className="heading-holder">
              <h3>User Name: {data?.user_name}</h3>
              <h4>Amount: {data?.wallet?.amount}</h4>
            </div>
          </div>

        </div>
      </section>
    </>
  );
}

export default Profile_data_info;
