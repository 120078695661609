import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";

import {
  GET,
  POST,
  PUT,
  DELETE,
  DownloadExcel,
  getDimension,
} from "./api";

import { Per_Page_Dropdown, Select2Data, RequiredIs } from "./common";

export const Context = createContext();
var HtmlToReactParser = require("html-to-react").Parser;
var htmlToReactParser = new HtmlToReactParser();
const AppContext = ({ children }) => {
  // const IMG_URL = "http://192.168.16.40:2999";
  const IMG_URL = "http://warrisportsnode.profcymabackend.com:2999";

  const navigate = useNavigate();

  const [signin, setSignin] = useState(false);
  // const [language, setLanguage] = useState(1);
  const [usertype, setUsertype] = useState("");
  const [userdata, setUserData] = useState({});
  const [isAllow, setisAllow] = useState([]);

  useEffect(() => {
    isTokenValid();
  }, [signin]);

  const getuserData = async (id) => {
    const response = await GET(`/login/${id}`, { id: id });
    await setUserData(response?.data?.data);
    await setisAllow(response?.data.permissions);
  };

  const [user, setUser] = useState({});
  // const GetUser = async () => {
  //   const response = await GET(`/usersingleget`);
  //   if (response?.success) {
  //     console.log("user :-", response?.data);
  //     setUser(response?.data);
  //   }
  // };
  // useEffect(() => {
  //   GetUser();
  // }, []);

  const isTokenValid = async () => {
    const token = Cookies.get("warri_sport_admin_security");

    if (token) {
      // Decode the token to get the expiration time
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Date.now() / 1000;

      // Check if the token has expired
      if (decodedToken.exp < currentTime) {
        Cookies.remove("warri_sport_admin_security", { path: "/" });
        setSignin(false);
        setUsertype("");
        setisAllow([]);
        navigate("/");
      } else {
        setisAllow(decodedToken?.permissions);
        getuserData(decodedToken.user);
        setUsertype(decodedToken.rolename);
        setSignin(true);
      }
    } else {
      setisAllow([]);
      setSignin(false);
      setUsertype("");
      navigate("/");
    }
  };

  const ErrorNotify = (name) => {
    toast.error(`${name} deleted successfully.`, {
      position: "top-right",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { background: "red", color: "white" },
    });
  };

  // Togle
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  return (
    <Context.Provider
      value={{
        IMG_URL,
        GET,
        POST,
        // PUT,
        PUT,
        DELETE,
        signin,
        setSignin,
        usertype,
        setUsertype,
        userdata,
        setUserData,
        DownloadExcel,
        ErrorNotify,
        toggleSidebar,
        isSidebarOpen,
        setSidebarOpen,
        Select2Data,
        Per_Page_Dropdown,
        RequiredIs,
        getDimension,
        isAllow,
        user,
        htmlToReactParser,
        setisAllow,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
